import Navbar from "./NavBar/NavBar";

function Index() {
  return (
    <>
    </>
  );
}

export default Index;
