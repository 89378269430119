import Navbar from "../NavBar/NavBar";
import ServicesElevator from "../ServicesElevator/ServicesElevator";
import css from './Homepage.module.css'
import '../ImgCur/ImgCur.css'

import earth from '../../assets/gifs/earth.gif'
import bracket from '../../assets/photos/bracket.svg'
import driving from '../../assets/photos/driving-explore.png'


import meetme from '../../assets/photos/meetMe.svg'
import Carousel from '../Carousel/Carousel'
import Footer from "../Footer/Footer";
import TextDescrambler from "../TextDescrambler/TextDescrambler";
import ImgCur from "../ImgCur/ImgCur";
import { useState } from "react";
import ScrollIndicator from "../ScrollIndicator/ScrollIndicator";

function Homepage() {
    const [hoveringMeet, setHoveringMeet] = useState(false);      

    const phrases = [
        '',
        `I'm Matt, a Software Engineer working remotely for <span class="extrahop-span">ExtraHop</span> on their website and digital tradeshow experiences in sunny Seattle, Washington.`
      ];
      const phrases2 = [
        '',
        `Crafting  Digital  Experiences:  Where  Software  Engineering  and  Design  Converge`
      ];
    return (
        <>
            <div style={{ 
                textAlign: 'center',
                fontWeight: 'bold',
                padding: '1px',
                backgroundColor: 'black',
                color: 'white'

            }}>
                <h4>Looking to hire a game developer? <a
                style={{
                    color: 'cyan',
                    textDecoration: 'none'
                }} target="_blank" href='https://dungeon-browser.web.app/spline'>&nbsp; Check out my newest project</a></h4>
            </div>
            <section className={css.hero}>
                <div className={css.titleCard}>
                    <Navbar />
                    <div className="row center">
                        <img id="meetmeImage"
                        //  onMouseEnter={() => setHoveringMeet(true)}
                        //  onMouseLeave={() => setHoveringMeet(false)}
                         className={`${css.meetme} custom-cursor-target`} src={meetme} alt="Meet Me" />
                        <ImgCur visible={hoveringMeet}/>

                    </div>
                    <div className="row center">
                        <div className={css.mission}>
                            <TextDescrambler phrases={phrases2}/>
                        </div>
                        {/* <p className={css.mission}>Crafting  Digital  Experiences:  Where  Software  Engineering  and  Design  Converge</p> */}
                    </div>
                </div>
                <div className={css.servicesCard}>
                    <ServicesElevator />
                </div>
            </section>
            <ScrollIndicator />
            <section className={css.portfolioGrid}>
                <h1 className={css.p0} >Recent projects</h1>
                <div className={css.p1}>
                    <div className={css.p1Child}>Wedding<br/>Websites</div>
                </div>
                <div className={css.p2}>
                    <img src={earth} />
                    <div className={css.p2Child}>Cloud<br/>Services</div>
                </div>
                <div className={css.p3}>
                    <div className={css.p3Child}>Sales<br/>Apps</div>
                </div>
                <div className={css.p4}>
                    <div className={css.p4Child}>Game<br/>Development</div>
                </div>
                <div className={css.p5}>
                    <div className={css.p5Child}>Corprate<br/>Marketing</div>
                </div>
                <div className={css.p6}>
                    <div className={css.p6Child}>Small<br/>Business</div>
                </div>
            </section>

            <section id="about" name="about" className={css.cSectionContainer}>
                <p className={css.bio}>
                    <TextDescrambler phrases={phrases}/></p>
                <img src={bracket} className={css.bracket}/>
                <Carousel />
            </section>
            <div
                style={{
                    padding: '170px',
                    backgroundImage: `url(${driving})`,
                    backgroundSize: 'cover',
                    color: 'white'
                }}
            >
                <h1
                style={{ marginTop: '-90px' }}>Passions include family, game dev, and soccer </h1>
                <p
                style={{ fontSize:'11px', marginTop: '-10px' }}
                >Screenshot from <b>lofi racing</b>, a personal project</p>

            </div>
            <Footer />
        </>
    );
}

export default Homepage;
