import React, { useEffect } from 'react';
import './ScrollIndicator.css';

import arrow from '../../assets/photos/arrow.svg'

const ScrollIndicator = () => {
  useEffect(() => {
    const timeout = setTimeout(() => {
      const indicator = document.querySelector('.scroll-indicator');
      if (indicator) {
        indicator.classList.add('fadeOut');
      }
    }, 8 * 1000); // 8 seconds for longer bouncing

    return () => clearTimeout(timeout);
  }, []);

  return <div className="scroll-indicator"><img className="scroll-arrow" src={arrow} alt="scroll down"/></div>;
};

export default ScrollIndicator;
